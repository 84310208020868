import React, { useEffect } from "react";
import Layout from "../components/layout";
import PublicationTiles from "../components/publicationTiles";
import { Helmet } from "react-helmet";
import { Container, Tabs, Tab, Col, Row } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import enrolIcon from "../assets/icons/icon-enrol.png";
import SectionHeader from "../components/sectionHeader";
import "../components/publications.scss";

function Publications({ data }) {
  console.log(data);
  const allArticles = data.allStrapiCategories.nodes.map((data, index) =>
    data.articles.map((item) => (
      <Col xs={12} sm={4} md={4} xl={3}>
        <div className="our-purpose__card">
          <PublicationTiles
            title={item.Title}
            text={item.Description}
            link={item.slug}
          />
        </div>
      </Col>
    ))
  );
  return (
    <Layout>
      <Helmet title="publications" defer={false} />

      <Container className="publications">
        <Row>
          <Col>
            <SectionHeader icon={enrolIcon} title="Publications" />
          </Col>
        </Row>

        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab-example"
          className="benefits-tabs publication"
        >
          <Tab eventKey="all" title="All">
            <Row>{allArticles}</Row>
          </Tab>
          {data.allStrapiCategories.nodes.map((data, index) => (
            <Tab eventKey={data.name} title={data.name}>
              <Row>
                {data.articles.map((item) => (
                  <Col xs={12} sm={4} md={4} xl={3}>
                    <div className="our-purpose__card">
                      <PublicationTiles
                        title={item.Title}
                        text={item.Description}
                        link={item.slug}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </Layout>
  );
}
export const pageQuery = graphql`
  query {
    allStrapiCategories {
      nodes {
        name
        articles {
          Title
          Description
          slug
        }
      }
    }
  }
`;
export default Publications;
