import React from "react";
import sectionHeaderStyles from "./publication.module.scss";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";

const PublicationTiles = (props) => {
  return (
    <div className={`${sectionHeaderStyles.sectionHeader}`}>
      <img src={props.icon} width="40"></img>
      <Link to={`/${props.link}`}>
        <h2>
          {props.title}
          <span className="highlight">&nbsp;{props.highlight}&nbsp;</span>
          {props.title1}
        </h2>
      </Link>
      <ReactMarkdown source={props.text} />
    </div>
  );
};

export default PublicationTiles;
